import { BrowserModule } from '@angular/platform-browser';
import { NgModule  } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './components/app/app.component';
import { HomeComponent } from './components/home/home.component';
import { IdentityService } from './services/identity.service';
import { AuthInterceptor,HTTPStatus } from './services/auth.interceptor';
import { AdalService, AdalGuard } from 'adal-angular4';

import { FormsModule } from '@angular/forms';

/* Shared Modules */
import { SharedModule } from './shared-module/shared.module';
import { DashboardModule } from './dashboard-module/dashboard.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthenticationGuard } from './shared-module/guards/authentication-guard';
import { AppInsightsService } from './services/app-insights.service';
//


const routes = [
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'logout', pathMatch: 'full', component: LogoutComponent },
  { path: '9box/logout', pathMatch: 'full', component: LogoutComponent },
  { path: '', redirectTo: '/ampp/analysis', pathMatch: 'full', canActivate: [AuthenticationGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard], canActivateChild: [AuthenticationGuard]},
        // Lazy Loading
  { path: 'ampp', loadChildren: './ampp-module/ampp.module#AmppModule', canActivate: [AuthenticationGuard], canActivateChild: [AuthenticationGuard] },
  { path: '9box', loadChildren: './dashboard-module/dashboard.module#DashboardModule', canActivate: [AuthenticationGuard], canActivateChild: [AuthenticationGuard]}
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
   // ExactDatePipe
   ],
   imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
     SharedModule,
     NgbModule,
     NgbModule.forRoot()
  ],
  providers: [
    AuthenticationGuard,
    AdalGuard,
		AdalService,
		IdentityService,
		HTTPStatus,
    AuthInterceptor,
    AppInsightsService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
