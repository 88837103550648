import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogService } from './services/dialog.service';
import { LoaderComponent } from './components/loader/loader.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TopNavComponent } from './components/nav/nav-top.component';

import { FullScreenModalComponent } from './components/modal/full-screen-modal.component';

@NgModule({
	exports: [
	BreadcrumbComponent,
        LoaderComponent,
      TopNavComponent,
      FullScreenModalComponent
	],
	declarations: [
	BreadcrumbComponent,
	DialogComponent,
        LoaderComponent,
        TopNavComponent,
	FullScreenModalComponent
	],
	imports: [
        CommonModule,
	RouterModule
	],
	providers: [
		DialogService
		],
	entryComponents: [DialogComponent]
})
export class SharedModule {}
