import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable,BehaviorSubject,throwError } from 'rxjs';
import { catchError,map,finalize} from 'rxjs/operators';
import { AdalService } from 'adal-angular4';


export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
		this.requestInFlight$.next(inFlight);
	}

  getHttpStatus(): Observable<boolean> {
	  return this.requestInFlight$.asObservable();
  }
}
@Injectable()

export class AuthInterceptor implements HttpInterceptor {

	constructor(private adalService: AdalService,
				private status: HTTPStatus) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.adalService.userInfo.authenticated) {
			// Clone the request to add the new header.
		const authReq = req.clone({setHeaders: { Authorization: 'Bearer ' + this.adalService.userInfo.token }});
			// Pass on the cloned request instead of the original request.
		this.status.setHttpStatus(true);
			return next.handle(authReq).pipe(
				map(event => {
					return event;
				}),
				catchError(error => {
                  return throwError(error);
				}),
				finalize(() => {
					this.status.setHttpStatus(false);
				//	this.cdref.detectChanges();
				})
			)
	//	return next.handle(authReq);
			
		} else {
			this.status.setHttpStatus(true);
			return next.handle(req).pipe(
				map(event => {
					return event;
				}),
				catchError(error => {
					return Observable.throw(error);
				}),
				finalize(() => {
					this.status.setHttpStatus(false);
				})
			)
		}
	}

}

