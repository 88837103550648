// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   apiUrl: 'https://amppapidev2.dev.pulte.com/odata/',
   azureAuthProvider: {
     'clientId': '34856aa4-2a7a-4c1a-89cd-eaebd9dda629',
     'tenant': 'pulte.onmicrosoft.com',
     redirectUri: 'https://amppdev2.dev.pulte.com/login',
     postLogoutRedirectUri: 'https://amppdev2.dev.pulte.com/logout'
   },
   envName: 'dev2',
  production: false,
   applicationInsights: {
     instrumentationKey: '8e198059-37c5-4b28-a1c5-b2fb521f9cff'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
